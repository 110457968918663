import { Auth, connectAuthEmulator } from 'firebase/auth'
import { connectDatabaseEmulator, Database } from 'firebase/database'
import { connectFirestoreEmulator, Firestore } from 'firebase/firestore'
import { connectFunctionsEmulator, Functions } from 'firebase/functions'
import { connectStorageEmulator, FirebaseStorage } from 'firebase/storage'

import { environment } from '../environments/environment'

const emulatorsConfig = environment.feature.emulators

export const connectAuthEmulatorInDevMode = (auth: Auth) =>
	(emulatorsConfig.all.enabled || emulatorsConfig.auth.enabled) &&
	connectAuthEmulator(auth, `http://localhost:${emulatorsConfig.auth.port}`, { disableWarnings: true })

export const connectFirestoreEmulatorInDevMode = (firestore: Firestore) =>
	(emulatorsConfig.all.enabled || emulatorsConfig.firestore.enabled) &&
	connectFirestoreEmulator(firestore, 'localhost', emulatorsConfig.firestore.port)

export const connectStorageEmulatorInDevMode = (storage: FirebaseStorage) =>
	(emulatorsConfig.all.enabled || emulatorsConfig.storage.enabled) &&
	connectStorageEmulator(storage, 'localhost', emulatorsConfig.storage.port)

export const connectFunctionsEmulatorInDevMode = (functions: Functions) =>
	(emulatorsConfig.all.enabled || emulatorsConfig.functions.enabled) &&
	connectFunctionsEmulator(functions, 'localhost', emulatorsConfig.functions.port)

export const connectDatabaseEmulatorInDevMode = (database: Database) =>
	(emulatorsConfig.all.enabled || emulatorsConfig.database.enabled) &&
	connectDatabaseEmulator(database, 'localhost', emulatorsConfig.database.port)
