import { FirestoreTimestamp } from './common.model'
import { CourseModulePresentation } from './course-module.model'
import { CourseLessonPresentation } from './lesson.model'

export const COURSE_FIRESTORE_COLLECTION_NAME = 'courses'

/**
 * Root collection `courses`
 */
export interface Course {
	created_by: string
	updated_by: string
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-name Stripe Product}
	 */
	name: string
	name_en: string
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-description Stripe Product}
	 */
	description?: string
	description_en?: string

	cta_buy_text?: string
	cta_buy_text_en?: string

	/**
	 * @see {@link https://stripe.com/docs/api/prices/create#create_price-unit_amount Stripe Price}
	 */
	price?: number
	price_per_month?: number
	price_per_year?: number
	/**
	 * Puntamento all'immagine usata in vetrina
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-images Stripe Product}
	 */
	image_url?: string
	image_url_en?: string
	/**
	 * Puntamento alla vetrina
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-url Stripe Product}
	 */
	shop_url?: string
	shop_url_en?: string
	stripe_product_id: string
	stripe_price_id?: string | null
	stripe_price_per_month_id?: string | null
	stripe_price_per_year_id?: string | null
	paypal_product_id?: string
	// Validità
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-active Stripe Product}
	 */
	active: boolean
	/**
	 * @internal
	 */
	active_from_date: FirestoreTimestamp | null
	/**
	 * @internal
	 */
	active_to_date: FirestoreTimestamp | null
	// Lesson overview
	lesson_list: CourseLessonPresentation[]
	// teacher_email_list: string[];
	coach_list?: {
		id: string
		displayName: string | null
		coach_support_email: string | null
		photoURL: string | null
	}[]

	/**
	 * Enable modularization of the course
	 */
	enable_modules?: boolean
	/**
	 * Module overview
	 */
	module_list: CourseModulePresentation[]

	subscriber_count?: number

	/**
	 * Active Campaign Automation id Course purchased
	 */
	active_campaign_automation_id_course_purchased?: string

	/**
	 * @example c_qdfqdgpsvlp664lhnhrmta3d1a@group.calendar.google.com
	 */
	google_calendar_id?: string

	show_as_inactive?: boolean
}

export enum SubscriptionModeEnum {
	MONTHLY = 'MONTHLY',
	YEARLY = 'YEARLY'
}

export function isCourseFree(course: Course) {
	return !course.price && !course.price_per_month && !course.price_per_year
}
