export interface EmulatorsConfig {
	all: {
		enabled: boolean
	},
	auth: EmulatorConfig,
	functions: EmulatorConfig,
	firestore: EmulatorConfig,
	database: EmulatorConfig,
	hosting: EmulatorConfig,
	pubsub: EmulatorConfig,
	storage: EmulatorConfig,
}

export interface EmulatorConfig {
	enabled: boolean
	port: number
}

export const DisabledEmulatorsConfig: EmulatorsConfig = {
	all: { enabled: false },
	auth: { enabled: false, port: 9099 },
	functions: { enabled: false, port: 5001 },
	firestore: { enabled: false, port: 8080 },
	database: { enabled: false, port: 9000 },
	hosting: { enabled: false, port: 5000 },
	pubsub: { enabled: false, port: 8085 },
	storage: { enabled: false, port: 9199 },
}
