import { Component, Inject, LOCALE_ID, NgZone, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { CookiePolicyService } from '@byebyeq/bbq-engine-cookie-policy'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'
import { setBackForwardNavigationGestures } from "capacitor-plugin-ios-webview-configurator"
import { NGXLogger } from 'ngx-logger'
import { environment } from '../environments/environment'
import { NavigationService } from './core/navigation.service'

@Component({
	selector: 'app-root',
	template: `
    <router-outlet></router-outlet>
		<!-- [style.display]="ready ? undefined : 'none'" -->
		<!-- <router-outlet name="print"></router-outlet> -->

		<div
			*ngIf="isShowingRouteLoadIndicator || isShowingRouteResolveIndicator"
			class="mat-snack-bar-container router-load-indicator"
			i18n="@@loading"
		>
			Caricamento...
		</div>
  `,
	styles: [`:host {
		display: block;
		height: 100%;
	}`]
})
export class AppComponent implements OnInit {
	title = 'otb-academy';
	isShowingRouteLoadIndicator = false;
	isShowingRouteResolveIndicator = false;

	constructor(
		private readonly cookie: CookiePolicyService,
		@Inject(LOCALE_ID) public readonly language: string,
		private readonly logger: NGXLogger,
		private readonly meta: Meta,
		private readonly navigation: NavigationService,
		private readonly router: Router,
		private readonly zone: NgZone,
	) {
		this.cookie.showBanner()
	}

	async ngOnInit() {
		this.logger.debug('App ready')

		if (Capacitor.isNativePlatform()) {
			setBackForwardNavigationGestures(true)
			if (Capacitor.getPlatform() === 'android') {
				this.registerAndroidListener()
			}

			const themeColor = this.meta.getTag('name="theme-color"')?.content
			console.log(`Theme color %c${themeColor}`, `color: ${themeColor}; font-weight: bold;`)
			if (themeColor) {
				StatusBar.setBackgroundColor({ color: themeColor })
			}

			App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
				this.zone.run(() => {
					// Example url: https://beerswift.app/tabs/tab2
					// slug = /tabs/tab2
					const slug = event.url.split(environment.feature.domain.app).pop()
					if (slug) {
						let gotoUrl = slug.charAt(0) === '/' ? slug : `/${slug}`
						gotoUrl = gotoUrl.replace('/it-IT', '').replace('/en-US', '')
						this.logger.info(`Container opened with URL "${event.url}" » Routing app to "${gotoUrl}"`)
						this.router.navigateByUrl(gotoUrl)
					}
					// If no match, do nothing - let regular routing
					// logic take over
				})
			})
		}
	}

	private registerAndroidListener() {
		App.addListener('backButton', (data) => {
			if (data.canGoBack) {
				// window.history.back()
				if (!this.navigation.back()) {
					App.exitApp()
				}
			} else {
				App.exitApp()
			}
		})
	}
}
