import { Route } from '@angular/router'

export const appRoutes: Route[] = [
	{
		path: 'main',
		loadChildren: () =>
			import('./+main/main.module').then((mod) => mod.MainModule),
	},
	{
		path: '',
		redirectTo: 'main',
		pathMatch: 'full',
	},
	{ path: '**', pathMatch: 'full', redirectTo: 'main' },
]
