import { app } from './app'
import { feature } from './feature-configuration/feature-configuration.demo'

const firebase = {
	projectId: 'otb-ott-basic-dev',
	appCheckDebugKey: '6CEE3692-EDAB-4D27-8D07-58FDB2102C33',
	appId: '1:670433080434:web:fc3ef3b7963eb2538f7eeb',
	databaseURL: 'https://otb-ott-basic-dev-default-rtdb.europe-west1.firebasedatabase.app',
	storageBucket: 'otb-ott-basic-dev.appspot.com',
	locationId: 'europe-west',
	apiKey: 'AIzaSyDqFXS9nz7ndowj2x7HigX2hyWsLWtccY4',
	authDomain: 'otb-ott-basic-dev.firebaseapp.com',
	messagingSenderId: '670433080434',
	measurementId: 'G-TFWSGX6ZWM',
}

const facebook = {
	pixel: {
		measurementId: 'TBD',
	},
}

const google = {
	analytics: {
		debug: true,
	},
	recaptcha3SiteKey: '6LeXmtAcAAAAAOifE_VeSpkh2vIZ5DSibecOwFw8'
}

const stripe = {
	publishable_key: 'pk_test_51JkmICBUUuGGCjuN8XFzK6kWbkOzZ8pLZYwSwjL3ixEOIRYhsxHIjHvuBScGYxpMPA0RwjQFB3RlezdBbmdgugUC001zsYNufj',
	testmode: true,
}

export const environment = {
	production: true,
	app,
	facebook,
	feature,
	firebase,
	google,
	stripe,
}
