import { CreatableEntity } from './common.model'

export const COUPON_FIRESTORE_COLLECTION_NAME = 'coupons'

/**
 * Root collection `coupons`
 */
export interface Coupon extends CreatableEntity {
	id?: string
	name: string,
	name_uppercase?: string,
	type?: COUPON_TYPE
	amount: number
	expiration_type: COUPON_EXPIRATION_TYPE
	/**
	 * @type {number}
	 * seconds
	 */
	expiration_timestamp?: number
	max_redemptions?: number
	active?: boolean
	redemption_count?: number
	stripe_promocode_id?: string
	stripe_coupon_id?: string
	redeemed_by_customer?: boolean
}

export const COUPON_REDEMPTION_FIRESTORE_COLLECTION_NAME = 'coupon_redemptions'

/**
 * Root collection `coupon_redemptions`
 */
export interface CouponRedemption extends CreatableEntity {
	coupon_id: string
	course_id: string
	order_id: string
}

export enum COUPON_TYPE {
	PERCENTAGE,
	FIXED
}

export enum COUPON_EXPIRATION_TYPE {
	FOREVER,
	DATE_TIME,
	ONCE,
}
