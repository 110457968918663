import { FirestoreTimestamp } from './common.model'

export const CALENDAR_SLOTS_CONFIG_FIRESTORE_COLLECTION_NAME = 'calendar_slots_configs'

/**
 * Sub collection `courses/{courseId}/calendar_slots_configs`
 */
export interface CalendarSlotsConfig {
	created_by: string
	updated_by: string

	/**
	 * @see {@link https://developers.google.com/calendar/api/v3/reference/events#summary Event summary}
	 * Title of the event.
	 */
	summary: string
	/**
	 * @see {@link https://developers.google.com/calendar/api/v3/reference/events#description Event description}
	 * Description of the event. Can contain HTML.
	 */
	description?: string

	start_time: FirestoreTimestamp // 1/11 9:00
	end_time: FirestoreTimestamp // 1/11 18:00
	duration_min: number // 1h = 60
	interval_min: number // 15

	slot_list?: CalendarSlot[]
}
// 1/11 » 9:00-10:00 10:15-11:15 11:30-12:30 ...

export interface CalendarSlot {
	id: string
	start_time: FirestoreTimestamp
	end_time: FirestoreTimestamp
}
