import type Stripe from 'stripe'
import { PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID } from './platform-subscription.model'

export const USER_FIRESTORE_COLLECTION_NAME = 'users'

/**
 * Root collection `users`
 */
export interface User {
	uid: string
	email: string | null
	emailVerified: boolean
	photoURL: string | null
	displayName: string | null
	providerId?: string | null
	deleted_because?: string | null
	language_code?: string | null
	first_name?: string | null
	last_name?: string | null
	notification_tokens?: Record<string, string>
	/**
	 * Course ID list
	 */
	purchased_course_list?: string[]
	paypal_customer_id?: string
	stripe_customer_id?: string
	/**
	 * Course ID list
	 */
	coached_course_list?: string[]
	coach_support_email?: string
	is_coach?: boolean
	active_campaign_id?: string

	subscribed_course_map?: {
		[courseId: string]: Pick<Stripe.Subscription, 'status' | 'current_period_end'>
	}
}

export function hasPurchasedCourse(user: User, courseId: string) {
	const { purchased_course_list = [], subscribed_course_map = {} } = user
	return purchased_course_list.includes(courseId)
		|| (
			((subscribed_course_map[courseId]?.current_period_end || 0) * 1000) > Date.now() &&
			subscribed_course_map[courseId]?.status === 'active'
		)
		|| (
			((subscribed_course_map[PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID]?.current_period_end || 0) * 1000) > Date.now() &&
			subscribed_course_map[PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID]?.status === 'active'
		)
}

export function hasAtLeastOneActiveSubscription(user: User) {
	const { subscribed_course_map = {} } = user

	return Object.keys(subscribed_course_map).length
}

export function hasPlatformSubscription(user: User) {
	const { subscribed_course_map = {} } = user

	return (
		((subscribed_course_map[PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID]?.current_period_end || 0) * 1000) > Date.now() &&
		subscribed_course_map[PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID]?.status === 'active'
	)
}

export function getPurchasedCourseIdList(user: User) {
	const subscribedCourseMap = user.subscribed_course_map || {}
	const subscribedCourseList: string[] = []
	const now = Date.now()
	for (const [courseId, subscription] of Object.entries(subscribedCourseMap)) {
		if ((subscription.current_period_end * 1000 > now && subscription.status === 'active'))
			subscribedCourseList.push(courseId)
	}

	return [
		...(user.purchased_course_list || []),
		...subscribedCourseList
	]
}
