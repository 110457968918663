import { Location } from "@angular/common"
import { Injectable } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"

@Injectable({ providedIn: "root" })
export class NavigationService {
	private history: string[] = [];

	constructor(private router: Router, private location: Location) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.history.push(event.urlAfterRedirects)
			}
		})
	}

	back() {
		this.history.pop()
		if (this.history.length > 0) {
			this.location.back()
			return true
		} else {
			this.router.navigateByUrl("/")
			return false
		}
	}
}
