import { FirestoreTimestamp } from './common.model'

export const PLATFORM_SUBSCRIPTION_FIRESTORE_COLLECTION_NAME = 'platform_subscriptions'
export const PLATFORM_SUBSCRIPTION_FIRESTORE_DOCUMENT_ID = '1QV0rshccdPiu8mar8qM'

/**
 * Root collection `platform_subscriptions`
 */
export interface PlatformSubscription {
	created_by: string
	updated_by: string
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-name Stripe Product}
	 */
	name: string
	name_en?: string
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-description Stripe Product}
	 */
	description?: string
	description_en?: string
	description_html?: string
	description_html_en?: string
	/**
	 * Puntamento all'immagine usata in vetrina
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-images Stripe Product}
	 */
	image_url?: string
	image_url_en?: string
	/**
	 * @see {@link https://stripe.com/docs/api/products/create#create_product-active Stripe Product}
	 */
	active: boolean
	/**
	 * @internal
	 */
	active_from_date: FirestoreTimestamp | null
	/**
	 * @internal
	 */
	active_to_date: FirestoreTimestamp | null
	/**
	 * @see {@link https://stripe.com/docs/api/prices/create#create_price-unit_amount Stripe Price}
	 */
	// price?: number
	price_per_month?: number
	price_per_year?: number
	stripe_product_id: string
	// stripe_price_id?: string | null
	stripe_price_per_month_id?: string | null
	stripe_price_per_year_id?: string | null
}
