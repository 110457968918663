import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { ViewInFirestoreButtonModule } from '@byebyeq/bbq-engine-components/view-in-firestore-button'
import { ViewInStripeButtonModule } from '@byebyeq/bbq-engine-components/view-in-stripe-button'
import { CookiePolicyModule } from '@byebyeq/bbq-engine-cookie-policy'
import { FacebookPixelModule } from '@byebyeq/bbq-engine-facebook-pixel'
import { RendererModule } from '@byebyeq/bbq-engine-renderer'
import { SCRIPT_STORE } from '@byebyeq/bbq-engine-resource-loader'
import { SeoModule } from '@byebyeq/bbq-engine-seo'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { initializeAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { getApp, initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { browserLocalPersistence, browserPopupRedirectResolver, browserSessionPersistence, indexedDBLocalPersistence, initializeAuth, inMemoryPersistence } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { initializePerformance } from 'firebase/performance'
import { getStorage } from 'firebase/storage'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { appRoutes } from './app.routes'
import { connectAuthEmulatorInDevMode, connectDatabaseEmulatorInDevMode, connectFirestoreEmulatorInDevMode, connectFunctionsEmulatorInDevMode, connectStorageEmulatorInDevMode } from './emulators'

const baseDomain = environment.feature.domain.app.substring(8, environment.feature.domain.app.lastIndexOf('/'))

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatBottomSheetModule,
		MatSnackBarModule,
		RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
		LoggerModule.forRoot({
			enableSourceMaps: environment.feature.technical.enable_logger_debug,
			level: environment.feature.technical.enable_logger_debug ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.INFO,
			serverLogLevel: NgxLoggerLevel.OFF,
			// disableFileDetails: Capacitor.isNativePlatform(),
		}),
		NgxWebstorageModule.forRoot({
			prefix: environment.feature.technical.storage_prefix
		}),
		CookiePolicyModule.forRoot({
			enableFacebookPixel: !!environment.facebook.pixel.measurementId,
			enableGoogleAnalytics: true,
		}),
		FacebookPixelModule.forRoot({
			pixelId: environment.facebook.pixel.measurementId,
		}),
		RendererModule.forRoot(),
		SeoModule.forRoot({
			baseDomain,
			baseTitle: environment.feature.seo.base_title,
			enableOpenGraph: true,
			enableRobotsIndex: environment.feature.seo.enable_robots_index
		}),
		ViewInFirestoreButtonModule.forRoot({
			enable: !environment.production,
			firebaseProjectId: environment.firebase.projectId,
			firestoreEmulator: environment.feature.emulators.all.enabled || environment.feature.emulators.firestore.enabled,
			// firestoreEmulator: !environment.production,
		}),
		ViewInStripeButtonModule.forRoot({
			enable: !environment.production,
			testmode: !environment.production,
		})
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (logger: NGXLogger) => () => {
				logger.info('Initializing Firebase...', environment.firebase)
				const app = initializeApp(environment.firebase)
				logger.debug('Firebase APP initialized', getApp())

				// TODO pulire nel caso di capacitor
				if (environment.firebase.appCheckDebugKey)
					(self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = environment.firebase.appCheckDebugKey

				initializeAppCheck(app, {
					provider: new ReCaptchaV3Provider(environment.google.recaptcha3SiteKey),
					isTokenAutoRefreshEnabled: true
				})
				logger.debug('Firebase App Check initialized')

				const analytics = initializeAnalytics(app)
				setAnalyticsCollectionEnabled(analytics, false)
				logger.debug('Firebase Analytics initialized')

				// https://firebase.google.com/docs/auth/web/auth-state-persistence
				const auth = initializeAuth(app, {
					persistence: [
						indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence, inMemoryPersistence
					],
					popupRedirectResolver: Capacitor.isNativePlatform() ? undefined : browserPopupRedirectResolver
				})
				connectAuthEmulatorInDevMode(auth)
				logger.debug('Firebase Auth initialized')

				initializePerformance(app, {
					dataCollectionEnabled: true,
					instrumentationEnabled: true,
				})
				logger.debug('Firebase Performance initialized')

				const firestore = initializeFirestore(app, {
					localCache: persistentLocalCache({
						tabManager: persistentMultipleTabManager()
					})
				})
				connectFirestoreEmulatorInDevMode(firestore)
				logger.debug('Firebase Firestore initialized')

				const database = getDatabase(app)
				connectDatabaseEmulatorInDevMode(database)
				logger.debug('Firebase RTDB initialized')

				const storage = getStorage(app)
				connectStorageEmulatorInDevMode(storage)
				logger.debug('Firebase Storage initialized')

				const functions = getFunctions(app)
				connectFunctionsEmulatorInDevMode(functions)
				logger.debug('Firebase Functions initialized')
			},
			deps: [NGXLogger],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (logger: NGXLogger) => async () => {
				logger.info('Container platform', Capacitor.getPlatform())
				logger.info('Container native platform', Capacitor.isNativePlatform())
				if (Capacitor.isNativePlatform()) {
					logger.info('Container info', JSON.stringify(await App.getInfo()))
				}
				logger.info('Container opened with URL', (await App.getLaunchUrl())?.url)
			},
			deps: [NGXLogger],
			multi: true
		},
		{
			provide: SCRIPT_STORE, multi: true, useValue: {
				name: 'stripe',
				src: 'https://js.stripe.com/v3/',
			}
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
