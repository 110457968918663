import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
	enableProdMode()
}

function bootstrap() {
	setTimeout(() =>
		platformBrowserDynamic()
			.bootstrapModule(AppModule, {
				ngZoneEventCoalescing: true,
				ngZoneRunCoalescing: true
			})
			.catch((err) => console.error(err))
	)
}

if (document.readyState === 'complete') {
	bootstrap()
} else {
	document.addEventListener('DOMContentLoaded', bootstrap)
}
