import { DisabledEmulatorsConfig } from '@byebyeq/ott-models'
import type { Feature } from './feature-configuration.model'

export const feature = Object.freeze<Feature>({
	auth: {
		login: {
			enable_captcha: false,
			providers: {
				email: {
					enabled: true,
				},
				apple: {
					enabled: true,
				},
				facebook: {
					enabled: true,
				},
				google: {
					enabled: true,
				}
			}
		}
	},
	courses: {
		enable_modules: true,
	},
	domain: {
		root: 'https://solutions.byebyeq.com/',
		app: 'https://ott-demo.byebyeq.com/',
	},
	emulators: DisabledEmulatorsConfig,
	file_upload: {
		course: false,
		lesson: true,
	},
	seo: {
		enable_robots_index: false,
		base_title: 'ByeByeQ'
	},
	technical: {
		enable_logger_debug: true,
		enable_performance: true,
		enable_view_on_firestore: true,
		enable_view_on_stripe: true,
		show_errors: true,
		storage_prefix: 'ott_demo',
	},
	user: {
		extra_fields: {
			first_name: {
				enabled: true,
				required: true,
			},
			last_name: {
				enabled: true,
				required: false,
			}
		}
	},
	business_account: {
		copyright: 'Copyright © 2021-{{CURRENT_YEAR}}. ByeByeQ',
		facebook: 'https://www.facebook.com',
		instagram: 'https://www.instagram.com',
		terms: '#',
		privacy: '#',
		internal_privacy: true,
		internal_terms: true,
		support_email: 'solutions@byebyeq.com'
	}
})
